import React from 'react';
import { useSessionData } from '@confluence/session-data';

import RendererComponent from './RendererComponent';
import type { ReactElement } from 'react';
import type { ADNode } from '@atlaskit/editor-common/validator';
import type { AnnotationProviders } from '@atlaskit/editor-common/types';
import type { RendererAppearance } from '@atlaskit/renderer';
import type { ContentTypeType } from '../../actions/editor-action-types';

export type QueryProps = {
	id?: string;
	className?: string;
	style?: object;
	adf: ADNode | null;
	objectId: string;
	contentType?: ContentTypeType;
	spaceKey?: string;
	allowInlineComments?: boolean;
	allowHighlightActions?: boolean;
	appearance?: RendererAppearance;
	renderWhenReady?: () => ReactElement;
	ignoreHeadingAnchorLinksFeatureFlag?: boolean;
	showTemplateVariablesInPreview?: boolean;
	showTemplateInputInPreview?: boolean;
	onComplete?: (stats: any) => void;
	getHash?: () => string;
	allowPlaceholderText?: boolean;
	isPreviewMode?: boolean;
	isUsingPublicLinkInfo?: boolean;
};

export type ExternalProps = QueryProps & {
	accountId: string | null;
	cloudId: string;
	environment: string;
	allowStickyHeaders?: boolean;
	allowAnnotations?: boolean;
	annotationProvider?: AnnotationProviders;
	UNSTABLE_allowTableAlignment?: boolean;
	UNSTABLE_allowTableResizing?: boolean;
};

export default (props: QueryProps) => {
	const { userId, cloudId, environment } = useSessionData();
	return (
		<RendererComponent {...props} accountId={userId} cloudId={cloudId} environment={environment} />
	);
};
